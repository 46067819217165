import "@b2c/core/app/application.js";
import "./directive/**/*";
import '@b2c/core/app/components/**/auth-with-deposit-init-form.js';
import '@b2c/core/app/components/**/auth-with-deposit-login.js';
import '@b2c/core/app/components/**/auth-with-deposit-paysystems.js';
import '@b2c/core/app/components/**/auth-with-deposit-popup.js';
import '@b2c/core/app/components/**/auth-with-deposit-registration.js';
import '@b2c/core/app/components/**/auth-with-deposit-registration-bonus.js';
import '@b2c/core/app/components/**/batman-achievement-box1.js';
import '@b2c/core/app/components/**/batman-achievement-box2.js';
import '@b2c/core/app/components/**/batman-achievement-box3.js';
import '@b2c/core/app/components/**/batman-achievement-box4.js';
import '@b2c/core/app/components/**/batman-achievement-box5.js';
import '@b2c/core/app/components/**/batman-achievement-box6.js';
import '@b2c/core/app/components/**/batman-achievement-box7.js';
import '@b2c/core/app/components/**/batman-achievement-item1.js';
import '@b2c/core/app/components/**/batman-achievement-item2.js';
import '@b2c/core/app/components/**/batman-banking-box1.js';
import '@b2c/core/app/components/**/batman-banking-page1.js';
import '@b2c/core/app/components/**/batman-banner1.js';
import '@b2c/core/app/components/**/batman-banner10.js';
import '@b2c/core/app/components/**/batman-banner11.js';
import '@b2c/core/app/components/**/batman-banner12.js';
import '@b2c/core/app/components/**/batman-banner13.js';
import '@b2c/core/app/components/**/batman-banner14.js';
import '@b2c/core/app/components/**/batman-banner15.js';
import '@b2c/core/app/components/**/batman-banner2.js';
import '@b2c/core/app/components/**/batman-banner3.js';
import '@b2c/core/app/components/**/batman-banner4.js';
import '@b2c/core/app/components/**/batman-banner5.js';
import '@b2c/core/app/components/**/batman-banner6.js';
import '@b2c/core/app/components/**/batman-banner7.js';
import '@b2c/core/app/components/**/batman-banner8.js';
import '@b2c/core/app/components/**/batman-banner9.js';
import '@b2c/core/app/components/**/batman-bonus-money-item1.js';
import '@b2c/core/app/components/**/batman-bonus-money-item2.js';
import '@b2c/core/app/components/**/batman-bonus-money-item3.js';
import '@b2c/core/app/components/**/batman-bonus-money-item4.js';
import '@b2c/core/app/components/**/batman-bonus-spin-item1.js';
import '@b2c/core/app/components/**/batman-bonus-spin-item2.js';
import '@b2c/core/app/components/**/batman-bonus-spin-item3.js';
import '@b2c/core/app/components/**/batman-bonus-sport-item1.js';
import '@b2c/core/app/components/**/batman-bonus-sport-item2.js';
import '@b2c/core/app/components/**/batman-bonus-sport-item3.js';
import '@b2c/core/app/components/**/batman-bonus-sport-item4.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup1.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup2.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup3.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup4.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup5.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup6.js';
import '@b2c/core/app/components/**/batman-cash-crab-popup7.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit-form1.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit-form2.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit1.js';
import '@b2c/core/app/components/**/batman-cashbox-deposit2.js';
import '@b2c/core/app/components/**/batman-cashbox-withdraw-cancel1.js';
import '@b2c/core/app/components/**/batman-cashbox-withdraw-cancel2.js';
import '@b2c/core/app/components/**/batman-collection-list1.js';
import '@b2c/core/app/components/**/batman-countdown1.js';
import '@b2c/core/app/components/**/batman-countdown2.js';
import '@b2c/core/app/components/**/batman-game-box-favourites1.js';
import '@b2c/core/app/components/**/batman-game-box1.js';
import '@b2c/core/app/components/**/batman-game-box2.js';
import '@b2c/core/app/components/**/batman-game-box3.js';
import '@b2c/core/app/components/**/batman-game-box4.js';
import '@b2c/core/app/components/**/batman-game-hall1.js';
import '@b2c/core/app/components/**/batman-game-hall2.js';
import '@b2c/core/app/components/**/batman-holidays1.js';
import '@b2c/core/app/components/**/batman-icon-box1.js';
import '@b2c/core/app/components/**/batman-info-page1.js';
import '@b2c/core/app/components/**/batman-modal-wrapper1.js';
import '@b2c/core/app/components/**/batman-modal-wrapper2.js';
import '@b2c/core/app/components/**/batman-modal-wrapper3.js';
import '@b2c/core/app/components/**/batman-modal-wrapper4.js';
import '@b2c/core/app/components/**/batman-popup-wrapper1.js';
import '@b2c/core/app/components/**/batman-popup-wrapper11.js';
import '@b2c/core/app/components/**/batman-popup-wrapper12.js';
import '@b2c/core/app/components/**/batman-popup-wrapper4.js';
import '@b2c/core/app/components/**/batman-popup-wrapper5.js';
import '@b2c/core/app/components/**/batman-popup-wrapper6.js';
import '@b2c/core/app/components/**/batman-popup-wrapper7.js';
import '@b2c/core/app/components/**/batman-popup-wrapper8.js';
import '@b2c/core/app/components/**/batman-popup-wrapper9.js';
import '@b2c/core/app/components/**/batman-promo-item1.js';
import '@b2c/core/app/components/**/batman-promo-item2.js';
import '@b2c/core/app/components/**/batman-promo-item3.js';
import '@b2c/core/app/components/**/batman-promo-item4.js';
import '@b2c/core/app/components/**/batman-promo-item5.js';
import '@b2c/core/app/components/**/batman-promo-item6.js';
import '@b2c/core/app/components/**/batman-promo-item7.js';
import '@b2c/core/app/components/**/batman-promo-list1.js';
import '@b2c/core/app/components/**/batman-promo-list2.js';
import '@b2c/core/app/components/**/batman-promo-list3.js';
import '@b2c/core/app/components/**/batman-promo-list4.js';
import '@b2c/core/app/components/**/batman-promo-list5.js';
import '@b2c/core/app/components/**/batman-promo-list6.js';
import '@b2c/core/app/components/**/batman-promo-list7.js';
import '@b2c/core/app/components/**/batman-promo-list8.js';
import '@b2c/core/app/components/**/batman-promo-list9.js';
import '@b2c/core/app/components/**/batman-recently-played-list1.js';
import '@b2c/core/app/components/**/batman-recently-played-list2.js';
import '@b2c/core/app/components/**/batman-recently-played-list3.js';
import '@b2c/core/app/components/**/batman-registration1.js';
import '@b2c/core/app/components/**/batman-slider1.js';
import '@b2c/core/app/components/**/batman-slider2.js';
import '@b2c/core/app/components/**/batman-sport-card-item1.js';
import '@b2c/core/app/components/**/batman-sport-card-list1.js';
import '@b2c/core/app/components/**/batman-sport-squad-list1.js';
import '@b2c/core/app/components/**/batman-sport-team-item1.js';
import '@b2c/core/app/components/**/batman-sport-team-item2.js';
import '@b2c/core/app/components/**/batman-sport-team-list1.js';
import '@b2c/core/app/components/**/batman-sport-team-list2.js';
import '@b2c/core/app/components/**/batman-tournament-item1.js';
import '@b2c/core/app/components/**/batman-tournament-item2.js';
import '@b2c/core/app/components/**/batman-tournament-item3.js';
import '@b2c/core/app/components/**/batman-tournament-item4.js';
import '@b2c/core/app/components/**/batman-tournament-item5.js';
import '@b2c/core/app/components/**/batman-tournament-item6.js';
import '@b2c/core/app/components/**/batman-tournament-list1.js';
import '@b2c/core/app/components/**/batman-ui-wrapper1.js';
import '@b2c/core/app/components/**/batman-view-layer1.js';
import '@b2c/core/app/components/**/batman-view-layer2.js';
import '@b2c/core/app/components/**/batman-view-layer3.js';
import '@b2c/core/app/components/**/batman-view-layer4.js';
import '@b2c/core/app/components/**/batman-view-layer5.js';
import '@b2c/core/app/components/**/batman-view-layer6.js';
import '@b2c/core/app/components/**/batman-view-layer7.js';
import '@b2c/core/app/components/**/batman-view-layer8.js';
import '@b2c/core/app/components/**/batman-widget-last-deposit1.js';
import '@b2c/core/app/components/**/batman-withdrawal-item1.js';
import '@b2c/core/app/components/**/batman-zimpler-paynplay-widget1.js';
import '@b2c/core/app/components/**/cup-progress.js';
import '@b2c/core/app/components/**/cup-with-dynamic-achieve-mobile.js';
import '@b2c/core/app/components/**/funid-auth-flow.js';
import '@b2c/core/app/components/**/linda-achievements-page.js';
import '@b2c/core/app/components/**/linda-app.js';
import '@b2c/core/app/components/**/linda-article-box.js';
import '@b2c/core/app/components/**/linda-autobonus-popup.js';
import '@b2c/core/app/components/**/linda-balance-alert-popup.js';
import '@b2c/core/app/components/**/linda-bonus-activation-modal.js';
import '@b2c/core/app/components/**/linda-bonus-cancellation-modal.js';
import '@b2c/core/app/components/**/linda-bonus-list-mob-rabona.js';
import '@b2c/core/app/components/**/linda-callback-page.js';
import '@b2c/core/app/components/**/linda-cancel-pending-withdrawals-modal.js';
import '@b2c/core/app/components/**/linda-cashbox-balance-information.js';
import '@b2c/core/app/components/**/linda-cashbox-deposit-form.js';
import '@b2c/core/app/components/**/linda-cashbox-deposit-form-funid.js';
import '@b2c/core/app/components/**/linda-cashbox-popup.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-form.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-form-funid.js';
import '@b2c/core/app/components/**/linda-cashbox-withdraw-modal.js';
import '@b2c/core/app/components/**/linda-casino-section.js';
import '@b2c/core/app/components/**/linda-cookie-banner.js';
import '@b2c/core/app/components/**/linda-cup-page.js';
import '@b2c/core/app/components/**/linda-error-modal.js';
import '@b2c/core/app/components/**/linda-footer.js';
import '@b2c/core/app/components/**/linda-form-date-selector.js';
import '@b2c/core/app/components/**/linda-funid-code.js';
import '@b2c/core/app/components/**/linda-game-cash-crab.js';
import '@b2c/core/app/components/**/linda-game-field.js';
import '@b2c/core/app/components/**/linda-game-page.js';
import '@b2c/core/app/components/**/linda-game-page-mobile.js';
import '@b2c/core/app/components/**/linda-gdpr-registration-modal.js';
import '@b2c/core/app/components/**/linda-header.js';
import '@b2c/core/app/components/**/linda-homepage.js';
import '@b2c/core/app/components/**/linda-icon-box.js';
import '@b2c/core/app/components/**/linda-img.js';
import '@b2c/core/app/components/**/linda-info-page.js';
import '@b2c/core/app/components/**/linda-info-popup.js';
import '@b2c/core/app/components/**/linda-lang-popup.js';
import '@b2c/core/app/components/**/linda-lang-version-notification-modal.js';
import '@b2c/core/app/components/**/linda-login-form.js';
import '@b2c/core/app/components/**/linda-login-popup.js';
import '@b2c/core/app/components/**/linda-mga-session-length-modal.js';
import '@b2c/core/app/components/**/linda-missed-data-cancellation-modal.js';
import '@b2c/core/app/components/**/linda-missed-data-form.js';
import '@b2c/core/app/components/**/linda-modal-body.js';
import '@b2c/core/app/components/**/linda-multiple-quick-deposit.js';
import '@b2c/core/app/components/**/linda-notification.js';
import '@b2c/core/app/components/**/linda-payment-callback.js';
import '@b2c/core/app/components/**/linda-popup-body.js';
import '@b2c/core/app/components/**/linda-profile-bonus-list.js';
import '@b2c/core/app/components/**/linda-profile-change-password-form.js';
import '@b2c/core/app/components/**/linda-profile-settings.js';
import '@b2c/core/app/components/**/linda-promo-page.js';
import '@b2c/core/app/components/**/linda-promos.js';
import '@b2c/core/app/components/**/linda-registration-popup.js';
import '@b2c/core/app/components/**/linda-restoration-form.js';
import '@b2c/core/app/components/**/linda-restoration-new-pass-form.js';
import '@b2c/core/app/components/**/linda-restoration-popup.js';
import '@b2c/core/app/components/**/linda-search.js';
import '@b2c/core/app/components/**/linda-selfclosure-modal.js';
import '@b2c/core/app/components/**/linda-selfcontrol-accountclosure.js';
import '@b2c/core/app/components/**/linda-selfcontrol-add-form.js';
import '@b2c/core/app/components/**/linda-selfcontrol-change-exclusion-form.js';
import '@b2c/core/app/components/**/linda-selfcontrol-change-exclusion-popup.js';
import '@b2c/core/app/components/**/linda-selfcontrol-deposit.js';
import '@b2c/core/app/components/**/linda-selfcontrol-edit-form.js';
import '@b2c/core/app/components/**/linda-selfcontrol-selfexclusion.js';
import '@b2c/core/app/components/**/linda-selfcontrol-session.js';
import '@b2c/core/app/components/**/linda-selfcontrol-timeout.js';
import '@b2c/core/app/components/**/linda-selfcontrol-wrapper.js';
import '@b2c/core/app/components/**/linda-selfexclude-modal.js';
import '@b2c/core/app/components/**/linda-sidemenu.js';
import '@b2c/core/app/components/**/linda-sidemenu-right.js';
import '@b2c/core/app/components/**/linda-sitemap.js';
import '@b2c/core/app/components/**/linda-sport-page.js';
import '@b2c/core/app/components/**/linda-sport-team-page.js';
import '@b2c/core/app/components/**/linda-toolbar.js';
import '@b2c/core/app/components/**/linda-tournament-page.js';
import '@b2c/core/app/components/**/linda-view-layer-eight.js';
import '@b2c/core/app/components/**/linda-view-layer-five.js';
import '@b2c/core/app/components/**/linda-view-layer-four.js';
import '@b2c/core/app/components/**/linda-view-layer-one.js';
import '@b2c/core/app/components/**/linda-view-layer-seven.js';
import '@b2c/core/app/components/**/linda-view-layer-six.js';
import '@b2c/core/app/components/**/linda-view-layer-three.js';
import '@b2c/core/app/components/**/linda-view-layer-two.js';
import '@b2c/core/app/components/**/linda-vip.js';
import '@b2c/core/app/components/**/linda-widget-event-list.js';
import '@b2c/core/app/components/**/linda-withdrawal-payment-callback.js';
import '@b2c/core/app/components/**/linda-zimpler-paynplay-login-popup.js';
import '@b2c/core/app/components/**/linda-zimpler-paynplay-page.js';
import '@b2c/core/app/components/**/linda-zimpler-registration-modal.js';
import '@b2c/core/app/components/**/not-found.js';
import '@b2c/core/app/components/**/christmas-countdown.js';
import '@b2c/core/app/components/**/christmas-promo.js';
import '@b2c/core/app/components/**/christmas-promo-mobile.js';
import '@b2c/core/app/components/**/christmas-promo-popup.js';
import '@b2c/core/app/components/**/christmas-tournament.js';
import '@b2c/core/app/components/**/desc-easter-promo-popup.js';
import '@b2c/core/app/components/**/dvs-alert.js';
import '@b2c/core/app/components/**/dvs-item.js';
import '@b2c/core/app/components/**/dvs-page.js';
import '@b2c/core/app/components/**/dvs-popup.js';
import '@b2c/core/app/components/**/easter-countdown.js';
import '@b2c/core/app/components/**/easter-promo.js';
import '@b2c/core/app/components/**/easter-promo-mobile.js';
import '@b2c/core/app/components/**/easter-promo-popup.js';
import '@b2c/core/app/components/**/footer-default.js';
import '@b2c/core/app/components/**/halloween-countdown.js';
import '@b2c/core/app/components/**/halloween-promo.js';
import '@b2c/core/app/components/**/halloween-promo-mobile.js';
import '@b2c/core/app/components/**/halloween-tournament.js';
import '@b2c/core/app/components/**/linda-otp-components.js';
import '@b2c/core/app/components/**/linda-promo-wrapper.js';
import '@b2c/core/app/components/**/oktoberfest-holiday-welcome-popup.js';
import '@b2c/core/app/components/**/summer-after-game-popup.js';
import '@b2c/core/app/components/**/summer-completed-popup.js';
import '@b2c/core/app/components/**/summer-countdown.js';
import '@b2c/core/app/components/**/summer-countdown-short.js';
import '@b2c/core/app/components/**/summer-countdown-with-day.js';
import '@b2c/core/app/components/**/summer-exit-game-popup.js';
import '@b2c/core/app/components/**/summer-extra-progress.js';
import '@b2c/core/app/components/**/summer-holiday-item.js';
import '@b2c/core/app/components/**/summer-holiday-list.js';
import '@b2c/core/app/components/**/summer-how-work-popup.js';
import '@b2c/core/app/components/**/summer-landscape.js';
import '@b2c/core/app/components/**/summer-promo-page.js';
import '@b2c/core/app/components/**/summer-promo-wrapper.js';
import '@b2c/core/app/components/**/summer-reward-after-popup.js';
import '@b2c/core/app/components/**/summer-story-tell-modal.js';
import '@b2c/core/app/components/**/summer-super-reward-popup.js';
import '@b2c/core/app/components/**/summer-welcome-popup.js';
import '@b2c/core/app/components/**/survey-alert.js';
import '@b2c/core/app/components/**/world-cup-countdown.js';
import '@b2c/core/app/components/**/world-cup-popup-mobile1.js';
import '@b2c/core/app/components/**/world-cup-popup-mobile2.js';
import '@b2c/core/app/components/**/world-cup-popup1.js';
import '@b2c/core/app/components/**/world-cup-popup2.js';
import '@b2c/core/app/components/**/world-cup-promo.js';
import '@b2c/core/app/components/**/world-cup-promo-mobile.js';
import '@b2c/core/app/components/**/world-cup-sport-feed-list.js';
import '@b2c/core/app/components/**/world-cup-styles.js';
import '@b2c/core/app/components/**/world-cup-templates.js';
import '@b2c/core/app/components/base.js';