const _temp0 = require("./directive/add-focus- ‎on-click.js");
const _temp1 = require("./directive/anjouan-icon.js");
const _temp2 = require("./directive/get-social-links.js");
const _temp3 = require("./directive/rabona-copy-to-clipboard.js");
const _temp4 = require("./directive/rabona-linebreaks.js");
const _temp5 = require("./directive/rabona-link-for-os.js");
const _temp6 = require("./directive/rabona-registration-promocode.js");
const _temp7 = require("./directive/rabona-scroll-to.js");
const _temp8 = require("./directive/rabona-split-phone.js");
const _temp9 = require("./directive/rabona-track-phone-error.js");
const _temp10 = require("./directive/slick-rtl.js");
const _temp11 = require("./directive/social-links.js");
module.exports = {
  "add-focus- ‎on-click.js": _temp0,
  "anjouan-icon.js": _temp1,
  "get-social-links.js": _temp2,
  "rabona-copy-to-clipboard.js": _temp3,
  "rabona-linebreaks.js": _temp4,
  "rabona-link-for-os.js": _temp5,
  "rabona-registration-promocode.js": _temp6,
  "rabona-scroll-to.js": _temp7,
  "rabona-split-phone.js": _temp8,
  "rabona-track-phone-error.js": _temp9,
  "slick-rtl.js": _temp10,
  "social-links.js": _temp11
}