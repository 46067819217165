import { Subject } from 'rxjs'
import { take, filter, takeUntil } from 'rxjs/operators'

import '@b2c/core/app/service/configs/config.js';
import '@b2c/core/app/service/helper.js';
import '@b2c/core/app/service/rx/splash$.js';
import '@b2c/core/app/service/domain/system.js';

(function () {

    'use strict';

    const directive = {name: 'anjouanIcon'};

    controller.$inject = ['config', 'helper', 'splash$', 'system'];

    function controller(_config, _helper, _splash$, _system) {

        function link(scope, element, attrs) {
            const destroy$ = new Subject();
            attrs.$set('id', `anj-${$_CONFIG.anjouanId}`);
            attrs.$set('data-apg-seal-id', `${$_CONFIG.anjouanId}`);
            attrs.$set('data-apg-image-size', `128`);
            attrs.$set('data-apg-image-type', `basic-small`);

            if (typeof ANJ !== 'undefined') ANJ.init();

            _splash$
                .pipe(
                    filter((m) => m === 'loaded'),
                    take(1),
                    takeUntil(destroy$)
                )
                .subscribe(async (i) => {
                    _helper.dom.addScript(`https://${$_CONFIG.anjouanId}.snippet.anjouangaming.org/anj-seal.js`, () => {
                        ANJ.init();
                    });
                });

            scope.$onDestroy = () => {
                destroy$.next();
                destroy$.unsubscribe();
            };
        }

        return {
            restrict: 'A',
            link
        };
    }

    app.directive(directive.name, controller);

})();